.slider {
    overflow: hidden;
    position: relative;
}

.slider img {
    width: 100%;
    height: 100%;
}

.control-btn .next {
    position: absolute;
    top: 50%;
    z-index: 10;
    right: 5%;
}

.control-btn .prev {
    position: absolute;
    top: 50%;
    z-index: 10;
    left: 5%;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

/* -------slide-form-------- */

.slide-form h2 {
    margin-top: 30px;
}

.slide-form {
    position: absolute;
    top: 30%;
    left: 10;
    background-color: rgba(0, 0, 0, 0.7);
    width: 35%;
    height: 65vh;
    border-radius: 5px;
    color: #fff;
}

.slide-form input {
    margin-top: 20px;
}

.slide-form .submit {
    background-color: #178cd5fc;
}

@media screen and (max-width: 768px) {
    .slider {
      height: 65vh;
    }
    .slider img {
      height: 65vh;
    }
    .slide-form{
        left: 5%;
        top: 32%;
        width: 90%;
        height: 55vh;
    }
  }



/* -------slide-form-------- */


