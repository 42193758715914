@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.primary-btn {
  padding: 10px 20px;
  background-color: #178cd5fc;
  color: #fff;
  font-weight: 400;
  transition: 05s;
}

.primary-btn:hover {
  background-color: #fff;
  color: white;
}

.control-btn i {
  background-color: #178cd5fc;
  width: 45px;
  height: 45px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: white;
  font-size: 30px;
}
textarea,
input {
  width: 100%;
  padding: 20px;
  outline: none;
  border: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}